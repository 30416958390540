<template>
  <b-row class="px-1" align-v="center">
    <icon
      v-if="windowWidth >= 940 || (windowWidth <= 764 && windowWidth >= 360)"
      class="mr-1"
      :icon="icon"
    />

    <b-form-input
      v-if="textMode"
      v-model="lMin"
      type="number"
      placeholder="--"
      :style="{ width: windowWidth < 520 ? '70px' : '102px' }"
      @input="
        (value) => {
          if ((!lMax && +lMax !== 0) || +lMin > +lMax) {
            lMax = lMin
          }
        }
      "
    />

    <b-form-spinbutton
      v-else
      v-model="lMin"
      placeholder="--"
      class="w-20"
      :inline="windowWidth >= 520"
      :vertical="windowWidth < 520"
      :min="0"
      :max="Number.MAX_VALUE"
      @input="
        (value) => {
          if ((!lMax && lMax !== 0) || lMin > lMax) {
            lMax = lMin
          }
        }
      "
    />

    <div class="divider mx-1"></div>

    <b-form-input
      v-if="textMode"
      v-model="lMax"
      type="number"
      placeholder="--"
      :style="{ width: windowWidth < 520 ? '70px' : '102px' }"
      @input="
        (value) => {
          if ((!lMin && +lMin !== 0) || +lMax < +lMin) {
            lMin = lMax
          }
        }
      "
    />

    <b-form-spinbutton
      v-else
      v-model="lMax"
      class="w-20"
      placeholder="--"
      :inline="windowWidth >= 520"
      :vertical="windowWidth < 520"
      :min="0"
      :max="Number.MAX_VALUE"
      @input="
        (value) => {
          if ((!lMin && lMin !== 0) || lMax < lMin) {
            lMin = lMax
          }
        }
      "
    />

    <span
      v-if="windowWidth >= 940 || (windowWidth <= 764 && windowWidth >= 360)"
      class="label ml-1"
    >
      {{ label }}
    </span>
  </b-row>
</template>

<script>
import { BFormInput, BFormSpinbutton, BRow, BIcon } from 'bootstrap-vue'

import Icon from '@/components/Icon.vue'

export default {
  name: 'RangePicker',
  props: {
    textMode: Boolean,
    min: Number,
    max: Number,
    icon: String,
    label: String,
  },
  components: {
    BRow,
    BFormInput,
    BFormSpinbutton,
    BIcon,
    Icon,
  },
  data() {
    return {
      lMin: 0,
      lMax: 0,
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },
  watch: {
    lMin(value) {
      this.$emit('minChange', +value)
    },
    lMax(value) {
      this.$emit('maxChange', +value)
    },
  },
  created() {
    this.lMin = this.min
    this.lMax = this.max
  },
}
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  width: 30px;

  background-color: #9999;
}
</style>
