var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"px-1",attrs:{"align-v":"center"}},[(_vm.windowWidth >= 940 || (_vm.windowWidth <= 764 && _vm.windowWidth >= 360))?_c('icon',{staticClass:"mr-1",attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.textMode)?_c('b-form-input',{style:({ width: _vm.windowWidth < 520 ? '70px' : '102px' }),attrs:{"type":"number","placeholder":"--"},on:{"input":function (value) {
        if ((!_vm.lMax && +_vm.lMax !== 0) || +_vm.lMin > +_vm.lMax) {
          _vm.lMax = _vm.lMin
        }
      }},model:{value:(_vm.lMin),callback:function ($$v) {_vm.lMin=$$v},expression:"lMin"}}):_c('b-form-spinbutton',{staticClass:"w-20",attrs:{"placeholder":"--","inline":_vm.windowWidth >= 520,"vertical":_vm.windowWidth < 520,"min":0,"max":Number.MAX_VALUE},on:{"input":function (value) {
        if ((!_vm.lMax && _vm.lMax !== 0) || _vm.lMin > _vm.lMax) {
          _vm.lMax = _vm.lMin
        }
      }},model:{value:(_vm.lMin),callback:function ($$v) {_vm.lMin=$$v},expression:"lMin"}}),_c('div',{staticClass:"divider mx-1"}),(_vm.textMode)?_c('b-form-input',{style:({ width: _vm.windowWidth < 520 ? '70px' : '102px' }),attrs:{"type":"number","placeholder":"--"},on:{"input":function (value) {
        if ((!_vm.lMin && +_vm.lMin !== 0) || +_vm.lMax < +_vm.lMin) {
          _vm.lMin = _vm.lMax
        }
      }},model:{value:(_vm.lMax),callback:function ($$v) {_vm.lMax=$$v},expression:"lMax"}}):_c('b-form-spinbutton',{staticClass:"w-20",attrs:{"placeholder":"--","inline":_vm.windowWidth >= 520,"vertical":_vm.windowWidth < 520,"min":0,"max":Number.MAX_VALUE},on:{"input":function (value) {
        if ((!_vm.lMin && _vm.lMin !== 0) || _vm.lMax < _vm.lMin) {
          _vm.lMin = _vm.lMax
        }
      }},model:{value:(_vm.lMax),callback:function ($$v) {_vm.lMax=$$v},expression:"lMax"}}),(_vm.windowWidth >= 940 || (_vm.windowWidth <= 764 && _vm.windowWidth >= 360))?_c('span',{staticClass:"label ml-1"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }