<template>
  <div class="bs-stepper wizard-example">
    <div class="bs-stepper-header">
      <div
        class="step"
        data-target="#applicant"
        :class="{ active: step === 1 }"
        @click="step = 1"
      >
        <button type="button" class="step-trigger">
          <span class="bs-stepper-box">1</span>
          <div class="bs-stepper-label">
            <span class="bs-stepper-title">{{
              $t('demandsModel.stepper.applicant.title')
            }}</span>
            <span class="bs-stepper-subtitle">{{
              $t('demandsModel.stepper.applicant.description')
            }}</span>
          </div>
        </button>
      </div>

      <div v-if="windowWidth >= 530" class="line">
        <feather-icon icon="ChevronRightIcon" class="font-medium-2" />
      </div>

      <div
        class="step"
        data-target="#details"
        :class="{ active: step === 2 }"
        :style="{ 'pointer-events': !applicantValid ? 'none' : 'unset' }"
        @click="step = 2"
      >
        <button type="button" class="step-trigger">
          <span class="bs-stepper-box">2</span>
          <div class="bs-stepper-label">
            <span class="bs-stepper-title">{{
              $t('demandsModel.stepper.details.title')
            }}</span>
            <span class="bs-stepper-subtitle">{{
              $t('demandsModel.stepper.details.description')
            }}</span>
          </div>
        </button>
      </div>

      <div v-if="windowWidth >= 530" class="line">
        <feather-icon icon="ChevronRightIcon" class="font-medium-2" />
      </div>

      <div
        class="step"
        data-target="#info"
        :class="{ active: step === 3 }"
        :style="{ 'pointer-events': !detailsValid ? 'none' : 'unset' }"
        @click="step = 3"
      >
        <button type="button" class="step-trigger">
          <span class="bs-stepper-box">3</span>
          <div class="bs-stepper-label">
            <span class="bs-stepper-title">{{
              $t('demandsModel.stepper.furnitureRooms.title')
            }}</span>
            <span class="bs-stepper-subtitle">{{
              $t('demandsModel.stepper.furnitureRooms.description')
            }}</span>
          </div>
        </button>
      </div>
    </div>

    <div v-if="step === 1" class="bs-stepper-content">
      <div id="applicant" class="content">
        <!-- Form: Personal Info Form -->
        <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
          <b-form class="mt-1" @submit.prevent="handleSubmit({})">
            <b-row align-h="start">
              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Applicant"
                >
                  <b-form-group
                    :label="$t('demandsModel.selectApplicant')"
                    label-for="applicant"
                  >
                    <v-select
                      v-model="selectedApplicant"
                      clearable
                      :options="
                        applicants.map((a) => ({
                          label: `${a.name} ${a.lastName}`,
                          value: a,
                        }))
                      "
                      :placeholder="$t('demandsModel.selectApplicant')"
                      :state="getValidationState(validationContext)"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row align-v="start" align-h="between">
              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    label-for="name"
                    :label="$t('demandsModel.applicantName')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.name"
                      class="d-inline-block mr-1"
                      placeholder="John"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Last name"
                  rules="required"
                >
                  <b-form-group
                    label-for="lastName"
                    :label="$t('demandsModel.applicantLastName')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.lastName"
                      class="d-inline-block mr-1"
                      placeholder="Doe"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="DNI">
                  <b-form-group
                    label-for="dni"
                    :label="$t('demandsModel.applicantDni')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.dni"
                      class="d-inline-block mr-1"
                      placeholder="DNI"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-group
                    label-for="email"
                    :label="$t('demandsModel.applicantEmail')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.email"
                      class="d-inline-block mr-1"
                      placeholder="john.doe@gmail.com"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Telephone"
                >
                  <b-form-group
                    label-for="telephone"
                    :label="$t('demandsModel.applicantTelephone')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.telephone"
                      class="d-inline-block mr-1"
                      placeholder="939232102"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Cellphone"
                >
                  <b-form-group
                    label-for="cellphone"
                    :label="$t('demandsModel.applicantCellphone')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.cellphone"
                      class="d-inline-block mr-1"
                      placeholder="988776655"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3" lg="3">
                <validation-provider
                  #default="validationContext"
                  name="Address"
                >
                  <b-form-group
                    label-for="address"
                    :label="$t('demandsModel.applicantAddress')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.address"
                      class="d-inline-block mr-1"
                      placeholder="Address line"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3" lg="3">
                <validation-provider
                  #default="validationContext"
                  name="Zip Code"
                >
                  <b-form-group
                    label-for="zipCode"
                    :label="$t('demandsModel.applicantZipCode')"
                  >
                    <b-form-input
                      v-model="demandData.applicant.zipCode"
                      class="d-inline-block mr-1"
                      placeholder="90200"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3" lg="3">
                <validation-provider
                  #default="validationContext"
                  name="Province"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('demandsModel.applicantProvince')"
                    label-for="province"
                  >
                    <v-select
                      v-model="demandData.applicant.province"
                      :placeholder="$t('demandsModel.applicantProvince')"
                      :clearable="false"
                      :options="PROVINCES"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3" lg="3">
                <validation-provider
                  #default="validationContext"
                  name="City"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('demandsModel.applicantCity')"
                    label-for="city"
                  >
                    <v-select
                      v-model="demandData.applicant.city"
                      :placeholder="$t('demandsModel.applicantCity')"
                      :clearable="false"
                      :options="CITIES"
                      :state="getValidationState(validationContext)"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-2 pr-1" align-h="end">
              <b-button
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'demands-list' }"
              >
                {{ $t('buttons.cancel') }}
              </b-button>

              <b-button
                :variant="applicantValid ? 'primary' : 'secondary'"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="!applicantValid"
                @click="step = 2"
              >
                {{ $t('buttons.next') }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>

    <div v-if="step === 2" class="bs-stepper-content">
      <div id="details" class="content">
        <!-- Form: Personal Info Form -->
        <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
          <b-form class="mt-1" @submit.prevent="handleSubmit({})">
            <b-row align-v="start" align-h="between">
              <!-- Field: Property action-->
              <b-col cols="12" md="6" lg="6">
                <!--<validation-provider
                  #default="validationContext"
                  name="Property action"
                  rules="required"
                >
                  <b-form-group
                    label="Property action*"
                    label-for="property-action"
                  >
                    <v-select
                      v-model="propertyAction"
                      placeholder="Property action"
                      :clearable="false"
                      :options="propertyActions || []"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>-->

                <!-- Workaround to prevent select fields to conflict between forms -->
                <validation-provider style="display: none">
                  <b-form-group>
                    <v-select></v-select>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="Property types"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('demandsModel.propertyTypes')"
                    label-for="property-type"
                  >
                    <v-select
                      v-model="selectedPropertyTypes"
                      multiple
                      :placeholder="$t('demandsPlaceholders.propertyTypes')"
                      :clearable="false"
                      :options="(propertyTypes || []).map((e) => ({ ...e, label: e.label[$i18n.locale] }))"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="validationContext" name="Zones">
                  <b-form-group
                    :label="$t('demandsModel.zones')"
                    label-for="zones"
                  >
                    <v-select
                      v-model="selectedZones"
                      multiple
                      :placeholder="$t('demandsPlaceholders.zones')"
                      :clearable="false"
                      :options="zones || []"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="Sales person"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('demandsModel.salesPerson')"
                    label-for="salesPerson"
                  >
                    <v-select
                      v-model="salesPerson"
                      :placeholder="$t('demandsPlaceholders.salesPerson')"
                      :clearable="false"
                      :options="salesPersonUsers || []"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('alert.select.empty') }}
                      </span>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-row class="ma-0 pa-0" align-h="between">
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider
                      #default="validationContext"
                      name="Min price"
                      rules="required|integer|positive"
                    >
                      <b-form-group
                        :label="$t('demandsModel.minPrice')"
                        label-for="minPrice"
                      >
                        <b-form-input
                          id="minPrice"
                          v-model="demandData.minPrice"
                          placeholder="10000,00"
                          :state="
                            getValidationState(validationContext) &&
                            minPriceValidation
                          "
                        />

                        <b-form-invalid-feedback
                          :state="
                            getValidationState(validationContext) &&
                            minPriceValidation
                          "
                        >
                          {{
                            demandData.minPrice
                              ? $t('demandsModel.validation.minPrice')
                              : validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6" lg="6">
                    <validation-provider
                      #default="validationContext"
                      name="Max price"
                      rules="required|integer|positive"
                    >
                      <b-form-group
                        :label="$t('demandsModel.maxPrice')"
                        label-for="maxPrice"
                      >
                        <b-form-input
                          id="maxPrice"
                          v-model="demandData.maxPrice"
                          placeholder="100000,00"
                          :state="
                            getValidationState(validationContext) &&
                            maxPriceValidation
                          "
                        />

                        <b-form-invalid-feedback
                          :state="
                            getValidationState(validationContext) &&
                            maxPriceValidation
                          "
                        >
                          {{
                            demandData.maxPrice
                              ? $t('demandsModel.validation.maxPrice')
                              : validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  :label="$t('demandsModel.observations')"
                  label-for="observations"
                >
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="demandData.observations"
                    :placeholder="$t('demandsPlaceholders.observations')"
                    rows="8"
                  />
                </b-form-group>

                <b-row class="mt-1 px-1">
                  <p class="mr-1" style="font-weight: bold">
                    {{ $t('demandsModel.active') }}
                  </p>
                  <b-form-checkbox
                    v-model="demandData.isActive"
                    name="check-button"
                    switch
                  >
                    <span style="font-weight: 500">{{
                      demandData.isActive
                        ? $t('commons.switch.yes')
                        : $t('commons.switch.no')
                    }}</span>
                  </b-form-checkbox>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-2 pr-1" align-h="end">
              <b-button
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="step = 1"
              >
                {{ $t('buttons.previous') }}
              </b-button>

              <b-button
                :variant="detailsValid ? 'primary' : 'secondary'"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="!detailsValid"
                @click.prevent="step = 3"
              >
                {{ $t('buttons.next') }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>

    <div v-if="step === 3" class="bs-stepper-content">
      <div id="info" class="content">
        <!-- Form: Personal Info Form -->
        <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit, invalid }"
        >
          <b-form class="mt-1" @submit.prevent="handleSubmit(submit())">
            <b-row align-v="start" align-h="between">
              <!-- Field: Property action-->
              <b-col cols="12" md="6" lg="6">
                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiBed" />
                  Bedrooms
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.bedrooms')"
                  :icon="mdiBed"
                  :min="demandData.minBedroom"
                  :max="demandData.maxBedroom"
                  @minChange="demandData.minBedroom = $event"
                  @maxChange="demandData.maxBedroom = $event"
                />

                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiShowerHead" />
                  Bathrooms
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.bathrooms')"
                  :icon="mdiShowerHead"
                  :min="demandData.minBathroom"
                  :max="demandData.maxBathroom"
                  @minChange="demandData.minBathroom = $event"
                  @maxChange="demandData.maxBathroom = $event"
                />

                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiToilet" />
                  Restrooms
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.restrooms')"
                  :icon="mdiToilet"
                  :min="demandData.minRestroom"
                  :max="demandData.maxRestroom"
                  @minChange="demandData.minRestroom = $event"
                  @maxChange="demandData.maxRestroom = $event"
                />

                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiNumeric3Circle" />
                  Years old
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.yearsOld')"
                  :icon="mdiNumeric3Circle"
                  :min="demandData.minYearOld"
                  :max="demandData.maxYearOld"
                  @minChange="demandData.minYearOld = $event"
                  @maxChange="demandData.maxYearOld = $event"
                />

                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiTextureBox" />
                  Surfaces
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.surfaces')"
                  :textMode="true"
                  :icon="mdiTextureBox"
                  :min="+demandData.minSurface"
                  :max="+demandData.maxSurface"
                  @minChange="demandData.minSurface = $event"
                  @maxChange="demandData.maxSurface = $event"
                />

                <span
                  v-if="
                    !(
                      windowWidth >= 940 ||
                      (windowWidth <= 764 && windowWidth >= 360)
                    )
                  "
                  class="demands__label"
                >
                  <feather-icon :icon="mdiFloorPlan" />
                  Plots
                </span>

                <range-picker
                  class="mb-1"
                  :label="$t('demandsModel.plots')"
                  :textMode="true"
                  :icon="mdiFloorPlan"
                  :min="+demandData.minPlot"
                  :max="+demandData.maxPlot"
                  @minChange="demandData.minPlot = $event"
                  @maxChange="demandData.maxPlot = $event"
                />
              </b-col>

              <b-col
                cols="12"
                md="6"
                lg="6"
                :class="{
                  'mt-3': $store.state.app.windowWidth <= 764,
                }"
              >
                <b-row class="px-1">
                  <b-col cols="12" sm="6" md="6" lg="6">
                    <b-row align-v="center">
                      <icon class="mr-1" :icon="mdiTableFurniture" />
                      <b-form-checkbox v-model="demandData.isFurnished">
                        {{ $t('demandsModel.booleans.isFurnished') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <icon class="mr-1" :icon="mdiGarage" />
                      <b-form-checkbox v-model="demandData.hasGarage">
                        {{ $t('demandsModel.booleans.hasGarage') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <icon class="mr-1" :icon="mdiAirConditioner" />
                      <b-form-checkbox v-model="demandData.hasAirConditioner">
                        {{ $t('demandsModel.booleans.hasAirConditioner') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <icon class="mr-1" :icon="mdiFlower" />
                      <b-form-checkbox v-model="demandData.hasGarden">
                        {{ $t('demandsModel.booleans.hasGarden') }}
                      </b-form-checkbox>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    :class="{
                      'mt-1': $store.getters['app/currentBreakPoint'] === 'xs',
                    }"
                  >
                    <b-row align-v="center">
                      <icon class="mr-1" :icon="mdiWindowOpenVariant" />
                      <b-form-checkbox v-model="demandData.hasTerrace">
                        {{ $t('demandsModel.booleans.hasTerrace') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <icon class="mr-1" :icon="mdiElevatorPassenger" />
                      <b-form-checkbox v-model="demandData.hasElevator">
                        {{ $t('demandsModel.booleans.hasElevator') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <icon class="mr-1" :icon="mdiPool" />
                      <b-form-checkbox v-model="demandData.hasPool">
                        {{ $t('demandsModel.booleans.hasPool') }}
                      </b-form-checkbox>
                    </b-row>

                    <b-row class="mt-1" align-v="center">
                      <feather-icon class="mr-1" icon="PackageIcon" />
                      <b-form-checkbox v-model="demandData.hasStorageRoom">
                        {{ $t('demandsModel.booleans.hasStorageRoom') }}
                      </b-form-checkbox>
                    </b-row>
                  </b-col>
                </b-row>

                <!--<validation-provider
                  #default="validationContext"
                  name="Kitchen type"
                  rules="required"
                >
                  <v-select
                    v-model="demandData.kitchenType"
                    class="mt-1"
                    :placeholder="$t('demandsModel.enumerators.kitchenType')"
                    :options="(KITCHEN_TYPES).map(kitchenType => ({
                      label: $t('enums.kitchenType.' + kitchenType),
                      value: kitchenType
                    }))"
                    item-value="value"
                    item-text="label"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>-->

                <!--<validation-provider
                  #default="validationContext"
                  name="Equipped kitchen"
                  rules="required"
                >
                  <v-select
                    v-model="demandData.equippedKitchen"
                    class="mt-1"
                    :placeholder="
                      $t('demandsModel.enumerators.equippedKitchen')
                    "
                    :options="(KITCHEN_EQUIPPED).map(equippedKitchen => ({
                      label: $t('enums.equippedKitchen.' + equippedKitchen),
                      value: equippedKitchen
                    }))"
                    item-value="value"
                    item-text="label"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>-->
              </b-col>
            </b-row>

            <b-row class="mt-2 pr-1" align-h="end">
              <b-button
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="step = 2"
              >
                {{ $t('buttons.previous') }}
              </b-button>
              <b-button
                type="submit"
                :variant="!invalid ? 'primary' : 'secondary'"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="invalid"
                ><b-spinner
                  v-if="loading"
                  small
                  :label="$t('commons.loading')"
                />
                <span v-else>{{ $t('buttons.save') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, positive, email } from '@validations'

import { ref, onUnmounted, computed } from '@vue/composition-api'

import {
  mdiAirConditioner,
  mdiBed,
  mdiElevatorPassenger,
  mdiFloorPlan,
  mdiFlower,
  mdiGarage,
  mdiNumeric3Circle,
  mdiPool,
  mdiShowerHead,
  mdiTableFurniture,
  mdiTextureBox,
  mdiToilet,
  mdiWindowOpenVariant,
} from '@mdi/js'

import router from '@/router'
import store from '@/store'

import DemandModel from '@/models/demand.model'
import demandStoreModule from './demandStoreModule'
import useDemandList from './useDemandList'

import RangePicker from './RangePicker'
import Icon from '@/components/Icon.vue'

import usePropertyTypesList from '../propertyTypes/usePropertyTypesList'
import usePropertyActionsList from '../propertyActions/usePropertyActionsList'
import useZonesList from '../zone/useZonesList'
import useUsersList from '../user/useUsersList'

import propertyTypesStoreModule from '../propertyTypes/propertyTypesStoreModule'
import propertyActionsStoreModule from '../propertyActions/propertyActionsStoreModule'
import zonesStoreModule from '../zone/zonesStoreModule'
import usersStoreModule from '../user/usersStoreModule'

import { localize } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    BSpinner,

    // Custom Components
    RangePicker,
    Icon,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      mdiAirConditioner,
      mdiBed,
      mdiElevatorPassenger,
      mdiFloorPlan,
      mdiFlower,
      mdiGarage,
      mdiNumeric3Circle,
      mdiPool,
      mdiShowerHead,
      mdiTableFurniture,
      mdiTextureBox,
      mdiToilet,
      mdiWindowOpenVariant,

      required,
      integer,
      positive,
      email,
      step: 1,
    }
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
    // KITCHEN_TYPES() {
    //   return this.$store.state.system.config.kitchenTypeOptions
    // },
    // KITCHEN_EQUIPPED() {
    //   return this.$store.state.system.config.equippedKitchenOptions
    // },
    loading() {
      return this.$store.state['app-demand'].loading
    },
    PROVINCES() {
      return this.$store.state.system.config.provinceOptions
    },
    CITIES() {
      return this.$store.state.system.config.cityOptions
    },
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
    },

    selectedApplicant(val) {
      this.demandData.applicant = val
        ? val.value
        : {
            province: 'MALAGA',
          }
    },
  },

  setup() {
    const blankDemandData = new DemandModel({
      applicant: {
        province: 'MALAGA',
      },
    })

    const demandData = ref(new DemandModel(blankDemandData))
    const propertyTypes = ref(null)
    const propertyActions = ref(null)
    const zones = ref(null)
    const salesPersonUsers = ref([])

    const applicants = ref([])
    const selectedApplicant = ref(null)

    const salesPerson = ref(null)
    const propertyAction = ref(1)

    const selectedPropertyTypes = ref([])
    const selectedZones = ref([])

    const DEMAND_APP_STORE_MODULE_NAME = 'app-demand'
    const PROPERTY_TYPES_APP_STORE_MODULE_NAME = 'app-property-types'
    const PROPERTY_ACTIONS_APP_STORE_MODULE_NAME = 'app-property-actions'
    const ZONES_APP_STORE_MODULE_NAME = 'app-zones'
    const USERS_APP_STORE_MODULE_NAME = 'app-users'

    const resetdemandData = () => {
      demandData.value = JSON.parse(JSON.stringify(blankDemandData))
    }

    // Register module
    if (!store.hasModule(DEMAND_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEMAND_APP_STORE_MODULE_NAME, demandStoreModule)
    }

    if (!store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_TYPES_APP_STORE_MODULE_NAME,
        propertyTypesStoreModule,
      )
    }

    if (!store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_ACTIONS_APP_STORE_MODULE_NAME,
        propertyActionsStoreModule,
      )
    }

    if (!store.hasModule(ZONES_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONES_APP_STORE_MODULE_NAME, zonesStoreModule)
    }

    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(USERS_APP_STORE_MODULE_NAME, usersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMAND_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEMAND_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)
      if (store.hasModule(ZONES_APP_STORE_MODULE_NAME))
        store.unregisterModule(ZONES_APP_STORE_MODULE_NAME)
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-demand/fetchOneDemand', router.currentRoute.params.id)
        .then((response) => {
          demandData.value = response
          demandData.value.applicant.province = 'MALAGA'
        })
        .catch((error) => {
          if (error.response.status === 404) {
            demandData.value = undefined
          }
        })
    } else {
      demandData.value = blankDemandData
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetdemandData)

    const { onSubmit } = useDemandList()

    const submit = () => {
      const demand = new DemandModel({
        ...demandData.value,
        salesPersonId: salesPerson.value.key,
        propertyActionId: propertyAction.value,
        propertyTypes: selectedPropertyTypes.value.map((p) => p.key),
        zones: selectedZones.value.map((z) => z.key),
      })

      onSubmit(demand)
    }

    store.dispatch('app-demand/fetchApplicants').then((apps) => {
      applicants.value = apps

      if (router.currentRoute.params.id !== 'new') {
        if (!demandData.value.applicant.id) {
          return
        }

        const index = applicants.value.findIndex(
          (item) => item.id === demandData.value.applicant.id,
        )

        if (index !== -1) {
          const appl = applicants.value[index]
          selectedApplicant.value = {
            label: `${appl.name} ${appl.lastName}`,
            value: appl,
          }
        }
      }
    })

    const { listPropertyTypes } = usePropertyTypesList()

    listPropertyTypes().then((response) => {
      propertyTypes.value = response

      if (router.currentRoute.params.id !== 'new') {
        demandData.value.propertyTypes.forEach((p) => {
          const index = propertyTypes.value.findIndex(
            (item) => item.key === p.id,
          )

          if (index !== -1) {
            selectedPropertyTypes.value.push(propertyTypes.value[index])
          }
        })
      }
    })

    const { listPropertyActions } = usePropertyActionsList()

    listPropertyActions().then((response) => {
      propertyActions.value = response

      // if (router.currentRoute.params.id !== 'new') {
      //   const index = propertyActions.value.findIndex(
      //     (item) => item.key === demandData.value.propertyActionId,
      //   )

      //   if (index !== -1) {
      //     propertyAction.value = propertyActions.value[index]
      //   }
      // }
    })

    const { listZones } = useZonesList()

    listZones().then((response) => {
      zones.value = response

      if (router.currentRoute.params.id !== 'new') {
        demandData.value.zones.forEach((p) => {
          const index = zones.value.findIndex((item) => item.key === p.id)

          if (index !== -1) {
            selectedZones.value.push(zones.value[index])
          }
        })
      }
    })

    const { listUsersByRole } = useUsersList()

    listUsersByRole('salesperson', true).then((response) => {
      salesPersonUsers.value = [...salesPersonUsers.value, ...response]

      if (router.currentRoute.params.id !== 'new') {
        const index = salesPersonUsers.value.findIndex(
          (item) => item.key === demandData.value.salesPersonId,
        )

        if (index !== -1) {
          salesPerson.value = salesPersonUsers.value[index]
        }

        return
      }

      const id1 = salesPersonUsers.value.find((sp) => sp.key === 1)

      if (id1) {
        salesPerson.value = id1
      }
    })

    listUsersByRole('superadmin', true).then((response) => {
      salesPersonUsers.value = [...salesPersonUsers.value, ...response]

      if (router.currentRoute.params.id !== 'new') {
        const index = salesPersonUsers.value.findIndex(
          (item) => item.key === demandData.value.salesPersonId,
        )

        if (index !== -1) {
          salesPerson.value = salesPersonUsers.value[index]
        }

        return
      }

      const id1 = salesPersonUsers.value.find((sp) => sp.key === 1)

      if (id1) {
        salesPerson.value = id1
      }
    })

    /**
     * Property that defines whether the min price is valid.
     */
    const minPriceValidation = computed({
      get: () => demandData.value && demandData.value.minPrice >= 0,
    })

    /**
     * Property that defines whether the max price is valid.
     */
    const maxPriceValidation = computed({
      get: () =>
        +demandData.value.maxPrice >= 0 &&
        (!demandData.value.minPrice ||
          +demandData.value.maxPrice >= +demandData.value.minPrice),
    })

    /**
     * Property that defines whether the details form is valid.
     */
    const detailsValid = computed({
      get: () =>
        propertyAction.value &&
        salesPerson.value &&
        selectedPropertyTypes.value &&
        selectedPropertyTypes.value.length &&
        minPriceValidation &&
        maxPriceValidation,
    })

    /**
     * Property that defines whether the applicant form is valid.
     */
    const applicantValid = computed({
      get: () =>
        demandData.value.applicant.name &&
        demandData.value.applicant.lastName &&
        demandData.value.applicant.province &&
        demandData.value.applicant.city &&
        demandData.value.applicant.email,
    })

    return {
      demandData,
      detailsValid,

      refFormObserver,
      getValidationState,
      resetForm,
      minPriceValidation,
      maxPriceValidation,

      submit,
      propertyTypes,
      propertyActions,
      zones,
      salesPersonUsers,

      applicants,
      selectedApplicant,

      salesPerson,
      propertyAction,
      selectedPropertyTypes,
      selectedZones,
      applicantValid,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.bs-stepper .bs-stepper-content #info {
  margin-left: 0;
}

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: $primary;
}

.demands__label {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  margin-bottom: 0.2rem;

  font-size: 0.857rem;
}
</style>
